<template>
    <v-progress-circular
        class="app-object--absolute-center"
        color="brand_primary"
        size="48"
        indeterminate
    />
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'ViewsOpen',
    mixins: [],
    props: {
        resource: {
            type: String,
        },
        id: {
            type: String,
        },
    },
    data() {
        return {}
    },
    computed: {
        ...mapGetters({
            user_is_authenticated: 'user_is_authenticated',
        }),
    },
    methods: {},
    created() {
        const payload = {
            name: 'ItemDisplay',
            params: {
                resource_id: this.id,
                resource: this.resource,
            },
        }

        if (this.$route.query.oauth && !this.user_is_authenticated) {
            payload.query = { oauth: this.$route.query.oauth }
        }

        this.$router.push(payload)
    },
}
</script>

<style scoped></style>
