import date_mixin from '@/mixins/common/date_mixin'
import { supported_locales } from '@/i18n'

// This state doesn't change and can therefore be defined once to avoid memory
// leaks by re-creating this object on every usage of locale_mixin
const locale_to_iso_3166_county_code_mapping_table = Object.freeze({
    'en': 'gb',
    'sv': 'se',
    'am': 'et',
    'ar': 'sa',
    'az': 'az',
    'bg': 'bg',
    'bn': 'bd',
    'bs': 'ba',
    'ceb': 'ph',
    'cs': 'cz',
    'da': 'dk',
    'de': 'de',
    'el': 'gr',
    'es': 'es',
    'et': 'ee',
    'fa': 'ir',
    'fi': 'fi',
    'fr': 'fr',
    'hi': 'in',
    'hr': 'hr',
    'hu': 'hu',
    'hy': 'am',
    'id': 'id',
    'ig': 'ng',
    'is': 'is',
    'it': 'it',
    'ja': 'jp',
    'jw': 'id',
    'ka': 'ge',
    'kk': 'kz',
    'km': 'kh',
    'ko': 'kr',
    'ku': 'iq',
    'lt': 'lt',
    'lv': 'lv',
    'mg': 'mg',
    'mk': 'mk',
    'ml': 'in',
    'mr': 'in',
    'ms': 'my',
    'my': 'mm',
    'ne': 'np',
    'nl': 'nl',
    'no': 'no',
    'ny': 'mw',
    'pa': 'pk',
    'pl': 'pl',
    'ps': 'af',
    'pt': 'pt',
    'ro': 'ro',
    'ru': 'ru',
    'sd': 'pk',
    'si': 'lk',
    'sk': 'sk',
    'sl': 'sl',
    'so': 'so',
    'sq': 'al',
    'sr': 'sr',
    'su': 'id',
    'sw': 'ke',
    'ta': 'in',
    'te': 'in',
    'th': 'th',
    'tl': 'ph',
    'tr': 'tr',
    'uk': 'ua',
    'ur': 'pk',
    'uz': 'uz',
    'vi': 'vn',
    'yo': 'ng',
    'zh-cn': 'cn',
    'zh-tw': 'cn',
})

export default {
    mixins: [date_mixin],
    computed: {
        locale_to_iso_3166_county_code_mapping_table() {
            return locale_to_iso_3166_county_code_mapping_table
        },
        current_language_iso_3166_code() {
            return this.locale_to_iso_3166_county_code_mapping_table[this.$i18n.locale]
        },
        current_language() {
            return this.$i18n.locale
        },
        languages() {
            return ['en', 'sv']
        },
        machine_translated_languages() {
            return supported_locales.filter((x) => this.languages.indexOf(x) === -1)
        },
    },
    methods: {
        translate(key, params = null) {
            if (this.$te(key)) return this.$t(key, params)

            return key
        },
        locale_key(key, locations, translate = false) {
            if (!key) return ''
            let re = new RegExp(/^[a-z.\-_0-9]*$/)
            if (!re.test(key)) return key
            if (!locations) locations = []
            locations = locations instanceof Array ? locations : [locations]
            if (locations.indexOf('common') === -1) locations.push('common')

            let keys = key.toLowerCase().split('.')

            let key_choices = keys.map((value, index) => keys.slice(index, keys.length).join('.'))
            if (key.includes('.')) {
                key_choices.unshift(key.replaceAll('.', '_'))
            }
            for (let key_choice of key_choices) {
                for (let location of locations) {
                    let location_key_choice = `${location.toLowerCase()}.${key_choice}`
                    if (this.$te(location_key_choice)) {
                        return translate ? this.$t(location_key_choice) : location_key_choice
                    }
                }

                if (this.$te(key_choice)) {
                    return translate ? this.$t(key_choice) : key_choice
                }
            }

            return key
        },
        translate_unknown_value(value, locations) {
            if (typeof value === 'string') return this.locale_key(value, locations, true)

            if (typeof value === 'boolean') {
                if (value) return this.$t('common.yes')
                return this.$t('common.no')
            }
            return value
        },
        key_locale_array(key_array, locations) {
            return key_array.map((key) => {
                let locale_key = JSON.parse(JSON.stringify(key.toLowerCase()))
                while (locale_key.startsWith('_')) {
                    locale_key = locale_key.substr(1)
                }
                locale_key = locale_key.split('.').pop()

                return {
                    key: key,
                    locale: this.locale_key(locale_key, locations, true),
                }
            })
        },
        to_iso_3166_code(locale) {
            return locale in this.locale_to_iso_3166_county_code_mapping_table
                ? this.locale_to_iso_3166_county_code_mapping_table[locale]
                : locale
        },
        format_as_currency(number, currency) {
            if (number === undefined || number === null) return number
            if (Math.abs(number) < 0.0000001) number = 0

            currency = currency || 'SEK'
            // let mapping_table = this.language_mapping_table
            // let locale_code = `${this.$i18n.locale}-${mapping_table[this.$i18n.locale].toUpperCase()}`
            return new Intl.NumberFormat(this.$i18n.locale, {
                style: 'currency',
                currency: currency,
                minimumFractionDigits: 2,
            }).format(number)
        },
        format_dict_value(value, value_config) {
            let re = new RegExp(/^[a-z.\-_0-9]*$/)
            if (value_config['format_as_currency']) {
                let currency = this.deep_get(value, value_config['currency_path'])
                return this.format_as_currency(value, currency)
            } else if (value_config['translate'] && re.test(value)) {
                return this.locale_key(value, value_config['locations'], true)
            } else if (value_config['format_as_datetime']) {
                return this.format_date_time(value)
            } else if (value_config['format_as_date']) {
                return this.format_date(value)
            } else if (value_config['uppercase']) {
                return value.toUpperCase()
            } else if (value_config['lowercase']) {
                return value.toLowerCase()
            } else if (value_config['boolean']) {
                return value ? this.translate('common.yes') : this.translate('common.no')
            }
            return value
        },
    },
}
