import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || 'en',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    messages: {
        [process.env.VUE_APP_I18N_LOCALE || 'en']: require(`./locales/${process.env.VUE_APP_I18N_LOCALE || 'en'}.json`),
        [process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en']: require(`./locales/${process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en'}.json`),
    },
})

const locale_regex = /^(?:.*\/)([A-Za-z0-9-_]+)\.json$/i
const require_context = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i, 'lazy')
export const supported_locales = require_context
    .keys()
    .map(path => path.replace(locale_regex, '$1'))

export async function setLocale (locale) {
    if (!i18n.availableLocales.includes(locale)) {
        if (!supported_locales.includes(locale)) {
            throw new TypeError(`Unsupported locale requested ${locale}`)
        }
        console.log('Loading locale', locale)
        const messages = await require_context(`./${locale}.json`)
        i18n.setLocaleMessage(locale, messages)
    }

    i18n.locale = locale
}

export default i18n
