<template>
    <v-navigation-drawer
        v-model="internal_value"
        :permanent="permanent"
        :width="$vuetify.breakpoint.xsOnly ? '90vw' : '550px'"
        class="rounded-l-xl"
        app
        right
        temporary
    >
        <div class="px-4 py-6 d-flex justify-space-between align-center">
            <div class="text-h5 font-weight-medium">
                {{ translate(title) }}
            </div>
            <v-btn
                @click="internal_value = false"
                color="primary"
                fab
                icon
                small
            >
                <v-icon> mdi-close</v-icon>
            </v-btn>
        </div>
        <v-divider />
        <div style="height: calc(100% - 89px); overflow-y: auto">
            <component
                ref="right_navigation_drawer_component"
                @close="internal_value = false"
                :is="component"
            />
        </div>
    </v-navigation-drawer>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import locale_mixin from '@/mixins/common/locale_mixin'
import { snakeCase, debounce } from 'lodash'

export default {
    name: 'TheRightNavigationDrawer',
    components: {
        SearchMenu: () => import(/* webpackChunkName: "Search"*/ '@/components/search_menu/Index'),
        WatchList: () => import(/* webpackChunkName: "WatchList"*/ '@/components/user_favorites/index.vue'),
        SettingsContainer: () => import(/* webpackChunkName: "Settings"*/ '@/components/settings_container/index.vue'),
        Notifications: () => import(/* webpackChunkName: "Notifications"*/ '@/components/notifications/index.vue'),
    },
    mixins: [locale_mixin],
    props: {
        value: Boolean,
        component: String,
    },
    data() {
        return {
            permanent: false,
            internal_value: null,
            header_height: 0,
        }
    },
    computed: {
        title() {
            if (this.component) return `common.${snakeCase(this.component)}`

            return 'common.navigation_drawer'
        },
        ...mapGetters([]),
        ...mapState(['partner_import_filters']),
    },
    watch: {
        internal_value: {
            handler(val) {
                this.$emit('input', val)

                this.update_permanent()
            },
            deep: true,
        },
        value(val) {
            this.internal_value = val
        },
        component() {
            this.update_permanent()
        },
    },
    methods: {
        update_permanent() {
            if (!this.internal_value) {
                this.permanent = false
                return
            }

            if (this.component === 'SettingsContainer' && this.partner_import_filters) {
                this.permanent = true
            }
        },
        ...mapActions(['set_state_property']),
    },
    beforeCreate() {},
    created() {
        this.internal_value = this.value
    },
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>
<style scoped></style>
