<template>
    <div class="user-authentication__bg pa-3">
        <v-row
            class="fill-height"
            no-gutters
        >
            <v-col v-if="$vuetify.breakpoint.mdAndUp" />
            <v-col
                :style="{ 'margin-bottom': $vuetify.breakpoint.mdAndUp ? '20vh' : '10vh' }"
                align-self="end"
                class="pa-4"
            >
                <v-alert
                    v-model="display_error"
                    color="error"
                    icon="mdi-alert-circle"
                    text
                >
                    <template #close>
                        <v-btn
                            @click="display_error = false"
                            icon
                            small
                        >
                            <v-icon small> mdi-close</v-icon>
                        </v-btn>
                    </template>
                    <b>{{ translate('common.login_failed') }}</b>
                    <br />
                    {{ translate(error) }}
                </v-alert>
                <div
                    class="d-flex align-center"
                    style="gap: 16px"
                >
                    <img
                        alt="amili"
                        height="24"
                        src="@/assets/logo_black.png"
                    />
                    <AppEnvironmentChip />
                </div>
                <div class="text-h1 my-4 font-weight-bold">
                    {{ translate('common.welcome_to_amili') }}
                </div>
                <p class="mb-6">
                    {{ translate('common.choose_login_method') }}
                </p>
                <section
                    :class="{ 'flex-wrap': $vuetify.breakpoint.smAndDown }"
                    class="d-flex"
                    style="gap: 1rem"
                >
                    <div
                        v-for="(authenticator, index) in authenticators"
                        :key="index"
                    >
                        <UserAuthenticatorOauth
                            :authenticator="authenticator"
                            @sign_in="sign_in($event)"
                        />
                    </div>
                </section>
                <BankID
                    v-model="display_bank_id"
                    @error="handle_bank_id_error"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import common_mixin from '@/mixins/common/mixin'
import UserAuthenticatorOauth from '@/components/user_authenticator/Oauth'
import AppEnvironmentChip from '@/components/app_environment_chip/index.vue'
import { multi_tab_state_mixin } from '@/mixins/multi_tab_state/mixin'

export default {
    name: 'UserAuthenticator',
    components: {
        AppEnvironmentChip,
        UserAuthenticatorOauth,
        BankID: () => import(/*webpackChunkName "BankID"*/ './BankID'),
    },
    mixins: [common_mixin, multi_tab_state_mixin],
    props: {
        authentication_error: String,
        auto_oauth: String,
    },
    data() {
        return {
            authenticators: [
                {
                    title: 'common.google',
                    image: 'google.png',
                    oauth: 'google',
                },
                {
                    title: 'common.microsoft',
                    image: 'microsoft.png',
                    oauth: 'microsoft',
                },
                {
                    title: 'common.visma_net',
                    image: 'visma_net.png',
                    oauth: 'visma_net',
                },
                {
                    title: 'common.scancloud',
                    image: 'scancloud.png',
                    oauth: 'scancloud',
                },
                {
                    title: 'common.mobile_bankid',
                    image: 'bankid.png',
                    oauth: 'bankid',
                },
            ],
            display_error: false,
            display_bank_id: false,
            display_bank_id_error: false,
        }
    },
    computed: {
        error() {
            if (this.display_bank_id_error) {
                return 'system.no_bankid_user'
            } else {
                return this.authentication_error
            }
        },
        ...mapGetters(['selected_token_json']),
        ...mapState(['redirect_url_on_login_success']),
    },
    watch: {
        authentication_error(value) {
            if (value) this.display_error = true
        },
        display_bank_id_error(value) {
            if (value) this.display_error = true
        },
        auto_oauth(value) {
            if (value && this.authenticators.some((authenticator) => authenticator.oauth === value)) {
                this.sign_in(value)
            }
        },
    },
    methods: {
        sign_in(oauth_authenticator) {
            if (oauth_authenticator === 'bankid') {
                return (this.display_bank_id = true)
            }

            this.set_state_property({
                state_property: 'redirect_url_on_login_success',
                data: this.get_redirect_url(),
            })

            let url = process.env.VUE_APP_API_ROOT + '/authenticates/oauth2' + '/' + oauth_authenticator + '/' + process.env.VUE_APP_NAME

            window.location.replace(url)
        },
        get_redirect_url() {
            let params_to_remove = ['oauth_authenticator', 'oauth']
            let url = this.redirect_url_on_login_success || window.location.href

            params_to_remove.forEach((param) => {
                url = this.remove_url_query_parameter(param, url)
            })

            return url
        },
        handle_bank_id_error() {
            this.display_bank_id_error = true
            this.display_bank_id = false
        },
        ...mapActions(['set_state_property']),
    },
    beforeCreate() {},
    created() {
        if (this.user_tokens) {
            return
        }

        let token_from_url = this.get_token_from_url()
        if (token_from_url) {
            this.update_multi_tab_data('user_tokens', token_from_url)
            this.set_state_property({
                state_property: 'user_login_date',
                data: this.format_date_time(new Date().toUTCString()),
            })
            this.user_activity('Authentication', 'portal', 'login')
            if (this.redirect_url_on_login_success) {
                if (window.location.href !== this.redirect_url_on_login_success) {
                    window.location.replace(this.redirect_url_on_login_success)
                }
                this.set_state_property({
                    state_property: 'redirect_url_on_login_success',
                    data: null,
                })
            }

            return
        }

        let oauth_authenticator_from_url = this.get_url_query_parameter('oauth_authenticator')
        if (oauth_authenticator_from_url) {
            this.sign_in(oauth_authenticator_from_url)
        }
    },
    beforeMount() {},
    async mounted() {
        const pre_auth_token = localStorage.getItem('pre_auth_token')
        if (pre_auth_token) {
            try {
                const response = await this.api_get({
                    url: '/authenticates/mobile-bankid',
                    token: pre_auth_token,
                })
                if (response && response.status === 200) {
                    if (response.data['status'] === 'completed') {
                        const { token_list } = response.data
                        if (token_list && token_list.length) {
                            this.update_multi_tab_data('user_tokens', {
                                token_list: token_list,
                                selected_token: 0,
                            })
                        }
                    }
                }
            } catch (e) {
                if (e.status === 404) {
                    this.display_bank_id_error = true
                }
            }
        }
        localStorage.removeItem('pre_auth_token')
    },
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>

<style scoped></style>
