<template>
    <div v-if="selected_token_json">
        <section
            class="d-flex align-center pb-4"
            style="gap: 32px"
        >
            <div class="text-h3 font-weight-medium">
                {{ String.format(translate('common.hello'), selected_token_json['u_name']) }}
            </div>
            <TheDashboardSelectCreditor v-model="selected_creditor" />
        </section>
        <TheDashboard :creditor_id="selected_creditor['_id']" />
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import common_mixin from '@/mixins/common/mixin'
import TheDashboard from '@/components/the_dashboard'
import TheDashboardSelectCreditor from '@/components/the_dashboard/select_creditor'

export default {
    name: 'ViewsHome',
    components: { TheDashboardSelectCreditor, TheDashboard },
    mixins: [common_mixin],
    props: [],
    data() {
        return {
            selected_creditor: {},
        }
    },
    computed: {
        ...mapGetters(['selected_token_json']),
        ...mapState([]),
    },
    watch: {},
    methods: {
        ...mapActions([]),
    },
    created() {},
    beforeCreate() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>
<style scoped></style>
