<template>
    <v-app class="login--container">
        <UserAuthenticator
            :auto_oauth="auto_oauth"
            :authentication_error="authentication_error"
        />
    </v-app>
</template>

<script>
import VueJwtDecode from 'vue-jwt-decode'
import { mapActions, mapGetters, mapState } from 'vuex'
import common_mixin from '@/mixins/common/mixin'
import UserAuthenticator from '@/components/user_authenticator'

export default {
    name: 'ViewsLogin',
    components: {
        UserAuthenticator,
    },
    mixins: [common_mixin],
    props: {},
    data() {
        return {
            authentication_error: '',
            auto_oauth: null,
        }
    },
    computed: {
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {},
    methods: {
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    async mounted() {
        let status_token = this.get_url_query_parameter('status_token')

        if (status_token) {
            const data = JSON.stringify({ token: status_token })
            const request = await fetch(process.env.VUE_APP_API_ROOT + '/validate-token', {
                method: 'POST',
                body: data,
                headers: { 'Content-Type': 'application/json' },
            })
            const response = await request.json()
            const status_token_json = VueJwtDecode.decode(status_token)
            const status = status_token_json['status']

            if (response.status === 'valid' && status === 'error') {
                this.authentication_error = status_token_json['messages'][0]
            }
        } else {
            this.auto_oauth = this.get_url_query_parameter('oauth')
        }

        const clean_url = window.location.origin + window.location.pathname
        window.history.replaceState({}, document.title, clean_url)

        // Use to calculate the KB size of entire or selected keys in local storage
        // console.log('local storage size:', this.local_storage_size(['permanent_storage']))
    },
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>

<style scoped>
.login--container {
    width: 100vw;
    position: absolute;
    left: 0;
    top: 0;
}
</style>
