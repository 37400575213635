<template>
    <v-scroll-y-transition>
        <section
            v-show="visible"
            class="px-6"
        >
            <v-divider />
            <v-card
                @click="handle_item"
                class="px-2 py-2 my-2"
                color="transparent"
                outlined
            >
                <div :class="text_class">
                    {{ text }}
                </div>
                <div
                    class="d-flex align-center"
                    style="gap: 8px"
                >
                    <v-chip
                        color="brand_secondary"
                        label
                        x-small
                    >
                        {{ chip }}
                    </v-chip>
                    <div class="caption app-opacity--level-7">|</div>
                    <div class="caption app-opacity--level-7 text-uppercase">
                        {{ item['creditor_name'] }}
                    </div>
                </div>
            </v-card>
        </section>
    </v-scroll-y-transition>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import locale_mixin from '@/mixins/common/locale_mixin'
import object_mixin from '@/mixins/common/object_mixin'

export default {
    name: 'TheDashboardTodoListItem',
    props: {
        item: {
            type: Object,
        },
        index: {
            type: Number,
        },
    },
    mixins: [locale_mixin, object_mixin],
    components: {},
    data() {
        return {
            visible: false,
        }
    },
    computed: {
        text() {
            switch (this.item.type) {
                case 'decision':
                    const language = this.current_language === 'sv' ? 'sv' : 'en'
                    const dotted_path = `decision_data.title.${language}`
                    const decision = this.deep_get(this.item, dotted_path)
                    const name = this.item['debtor_name'] || this.item['customer_name']
                    return `${decision} | ${name}`
                case 'ticket':
                    return String.format(this.translate('common.todo_new_ticket'), this.item['subject'])
                default:
                    return null
            }
        },
        chip() {
            switch (this.item.type) {
                case 'decision':
                    return this.translate('common.decision')
                case 'ticket':
                    return this.translate('common.tickets')
                default:
                    return null
            }
        },
        text_class() {
            return this.item?.read_by_requester ? 'mb-4 text--secondary app-text--overflow-hidden-ellipsis' : 'mb-4 font-weight-medium app-text--overflow-hidden-ellipsis'
        },
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {},
    methods: {
        handle_item() {
            switch (this.item.type) {
                case 'decision':
                    return this.$router.push(`/decision/${this.item['_id']}`)
                case 'ticket':
                    return this.$router.push(`/tickets/amili/ticket?ticket_id=${this.item['_id']}`)
                default:
                    return null
            }
        },
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {
        const max_timeout = 300
        const timeout = Math.min(this.index * 100, max_timeout)
        setTimeout(() => {
            this.visible = true
            if (timeout === max_timeout) this.$emit('completed')
        }, timeout)
    },
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>
<style scoped></style>
