<template>
    <v-app class="self-registration--container">
        <div class="self-registration__bg pa-3">
            <v-row
                class="fill-height"
                no-gutters
            >
                <v-col v-if="$vuetify.breakpoint.mdAndUp" />
                <v-col
                    :style="{ 'margin-bottom': $vuetify.breakpoint.mdAndUp ? '20vh' : '10vh' }"
                    align-self="end"
                    class="pr-4"
                >
                    <div
                        class="d-flex align-start mb-3"
                        style="gap: 16px"
                    >
                        <div class="d-flex">
                            <img
                                alt="amili"
                                height="24"
                                src="@/assets/logo_black.png"
                            />
                            <span class="self-registration--logo--ease-suffix"> ease </span>
                        </div>

                        <AppEnvironmentChip />
                    </div>

                    <div class="text-h1 mb-4 font-weight-bold">
                        {{ translate('common.register_an_account') }}
                    </div>
                    <p class="mb-9">
                        {{ translate('common.register_an_account_help_text') }}
                    </p>

                    <v-row>
                        <div
                            :class="{
                                'd-flex flex-column align-start px-3': true,
                                'full-width': $vuetify.breakpoint.xsOnly,
                            }"
                            style="gap: 1.25rem"
                        >
                            <v-btn
                                class="full-width"
                                dark
                            >
                                {{ translate('common.show_qr_code') }}
                                <img
                                    alt="amili"
                                    height="16"
                                    src="@/assets/bankid.png"
                                    style="filter: brightness(0) invert(1)"
                                    class="ml-3"
                                />
                            </v-btn>
                            <v-btn
                                :class="{ 'full-width': $vuetify.breakpoint.smAndDown }"
                                outlined
                            >
                                {{ translate('common.open_bankid_on_the_same_device') }}
                            </v-btn>
                        </div>
                    </v-row>

                    <v-row class="mt-10">
                        <v-col :class="{ 'text-center': $vuetify.breakpoint.xsOnly }">
                            <router-link :to="{ name: 'Login' }">
                                <a class="text-decoration-underline text-secondary">
                                    {{ translate('common.do_you_already_have_an_account') }}
                                </a>
                            </router-link>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>
    </v-app>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import common_mixin from '@/mixins/common/mixin'
import AppEnvironmentChip from '@/components/app_environment_chip/index.vue'

export default {
    name: 'ViewsLogin',
    components: { AppEnvironmentChip },
    mixins: [common_mixin],
    props: {},
    data() {
        return {}
    },
    computed: {
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {},
    methods: {
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>

<style scoped>
.self-registration--container {
    width: 100vw;
    position: absolute;
    left: 0;
    top: 0;
}

.self-registration--logo--ease-suffix {
    line-height: 2.1;
    margin-left: 5px;
}

.full-width {
    width: 100%;
}
</style>
